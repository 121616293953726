// Modules
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'

// Components
import Layout from 'components/global-components/Layout'
import MetaData from 'components/global-components/MetaData'
import ParentingBody from 'components/page-bodies/ParentingBody'

// PropTypes
const propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.shape({
        heroData: PropTypes.objectOf(PropTypes.any),
        introSection: PropTypes.objectOf(PropTypes.string),
        metadata: PropTypes.objectOf(PropTypes.any),
        programCardSection: PropTypes.objectOf(PropTypes.any),
      }),
    }),
  }),
}

const ParentingProgramsTemplate = ({ data }) => {

  const {
    markdownRemark: {
      frontmatter: {
        heroData,
        introSection,
        metadata,
        programCardSection,
      },
    },
  } = data

  return (
    <Layout>
      <MetaData metaData={metadata} />
      <ParentingBody
        heroData={heroData}
        introSection={introSection}
        programCardSection={programCardSection}
      />
    </Layout>
  )
}

ParentingProgramsTemplate.propTypes = propTypes
export default ParentingProgramsTemplate
export const pageQuery = graphql`
  query ParentingPageTemplate {
    markdownRemark(frontmatter: {templateKey: {eq: "parenting-programs"}}) {
      frontmatter {
        metadata {
          description
          title
          robots {
            follow
            index
          }
          ogImage {
            childImageSharp {
              fluid {
                originalImg
                originalName
              }
            }
          }
        }
        heroData {
          isReversed
          header
          curveNumber
          content
          fontColor {
            selectedColorOption
            customColor
          }
          backgroundColor {
            selectedColorOption
            customColor
          }
          imageData {
            imageAlt
            image {
              childImageSharp {
                fluid {
                  originalName
                  originalImg
                }
              }
            }
          }
        }
        introSection {
          content
          header
        }
        programCardSection {
          header
          programCards {
            title
            description
            cardColor {
              customColor
              selectedColorOption
            }
            imageData {
              imageAlt
              image {
                childImageSharp {
                  fluid {
                    originalImg
                    originalName
                  }
                }
              }
            }
            buttonText
          }
        }
      }
    }
  }
`
