// Modules
import PropTypes from 'prop-types'
import React from 'react'

// Components
import Hero from 'components/global-components/Hero'
import SubjectPageLayout from 'components/global-components/SubjectPageLayout'

// PropTypes
const propTypes = {
  heroData: PropTypes.objectOf(PropTypes.any).isRequired,
  introSection: PropTypes.shape({
    content: PropTypes.node,
    header: PropTypes.string,
  }),
  programCardSection: PropTypes.shape({
    header: PropTypes.string.isRequired,
    programCards: PropTypes.arrayOf(PropTypes.object).isRequired,
  }),
}

const ParentingBody = ({
  heroData,
  introSection,
  programCardSection,
}) => {
  const {
    content: introHeader,
    header: introContent,
  } = introSection

  const {
    header: programCardsHeader,
    programCards,
  } = programCardSection

  return (
    <>
      <Hero heroData={heroData} />
      <SubjectPageLayout
        introContent={introContent}
        introHeader={introHeader}
        programCardArray={programCards}
        programCardsHeader={programCardsHeader}
      />
    </>
  )
}

ParentingBody.propTypes = propTypes
export default ParentingBody
